import * as React from "react";
import { MessagingCard } from "./MessagingCard";
import { useLocalization } from "@shared-ui/localization-context";
import { MessageResult } from "__generated__/typedefs";

export const OverfilteringCard = () => {
  const { formatText } = useLocalization();

  const props: MessageResult = {
    title: {
      icon: {
        id: "info_outline",
        description: "",
        token: "",
        __typename: "Icon",
      },
      text: formatText("hotels.filters.overfiltering.title"),
      __typename: "MessagingResultTitle",
    },
    subtitle: {
      text: formatText("hotels.filters.overfiltering.subtitle"),
      __typename: "MessagingResultTitle",
    },
    content: [
      {
        __typename: "EGDSIconText",
        icon: {
          id: "info_outline",
          description: "",
          token: "",
          __typename: "Icon",
        },
        text: formatText("hotels.filters.overfiltering.title"),
      },
    ],
    __typename: "MessageResult",
  };

  return <MessagingCard {...props} />;
};

export default OverfilteringCard;
