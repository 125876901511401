import * as React from "react";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSFigure, EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { EGDSBlockSkeleton, EGDSLineSkeleton } from "@egds/react-core/skeleton";
import { liveAnnounce } from "@egds/react-core/utils";
import { useLocalization } from "@shared-ui/localization-context";

const results: string[] = new Array(10).fill(null).map((_, id) => `hotel-placeholder-${id}`);

export const PlaceholderResults: React.FC = () => {
  const { formatText } = useLocalization();

  liveAnnounce(formatText("propertyfilters.results.loading"));

  return (
    <div data-testid="loading-hotels">
      {results.map((key) => {
        return (
          <EGDSSpacing margin={{ block: "four", inline: "four" }} key={key}>
            <EGDSCard>
              <EGDSLayoutGrid columns={3} dataTestId="placeholder-card">
                <EGDSLayoutGridItem colSpan={1}>
                  <EGDSFigure
                    ratio={EGDSFigureAspectRatioType.R16_9}
                    className="uitk-card-roundcorner-top-left uitk-card-roundcorner-bottom-left"
                  >
                    <EGDSBlockSkeleton />
                  </EGDSFigure>
                </EGDSLayoutGridItem>
                <EGDSLayoutGridItem colSpan={2}>
                  <EGDSSpacing padding={{ block: "two", inline: "two" }}>
                    <div>
                      <EGDSSpacing margin={{ blockend: "two" }}>
                        <EGDSLineSkeleton animation type="primary" />
                      </EGDSSpacing>
                      <EGDSSpacing margin={{ blockend: "two" }}>
                        <EGDSLineSkeleton animation type="secondary" />
                      </EGDSSpacing>
                    </div>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>
              </EGDSLayoutGrid>
            </EGDSCard>
          </EGDSSpacing>
        );
      })}
    </div>
  );
};

export default PlaceholderResults;
