import * as React from "react";
import { useHotelsContext } from "./HotelsContext";
import { EGDSHeading, EGDSHeadingProps } from "@egds/react-core/text";
import { EGDSSpacing } from "@egds/react-core/spacing";

enum HotelView {
  affinity = "affinity",
}

export const HotelTitle: React.FC = () => {
  const { model } = useHotelsContext();

  switch (model.view) {
    case HotelView.affinity:
      if (!model.title) return null;
      return <Title className="affinityTitle">{model.title}</Title>;

    default:
      if (!model.title) return null;
      return <Title>{model.title}</Title>;
  }
};

type TitleProps = Pick<EGDSHeadingProps, "className" | "tag">;

const Title: React.FC<TitleProps> = ({ children, className, tag = "h2" }) => {
  return (
    <EGDSSpacing padding={{ blockstart: "three" }}>
      <EGDSHeading className={className} tag={tag} size={4}>
        {children}
      </EGDSHeading>
    </EGDSSpacing>
  );
};

export default HotelTitle;
