// External Libraries
import * as React from "react";
import { inject, observer } from "mobx-react";
import { Viewport, ViewSmall, ViewMedium } from "@shared-ui/viewport-context";
import { useLocalization } from "@shared-ui/localization-context";
import { InView } from "react-intersection-observer";

// UITK
import { EGDSCardContentSection } from "@egds/react-core/cards";
import { EGDSSubheading } from "@egds/react-core/text";
import { liveAnnounce } from "@egds/react-core/utils";
import { EGDSSpacing } from "@egds/react-core/spacing";

// Components
import { HotelCard } from "./components/HotelCard";
import { SeeAllContentLink } from "./components/SeeAllContentLink";
import { CardContentReviewsPriceProposition } from "components/flexComponents/Hotels/components/CardContentReviewsPriceProposition";
import { SeeAllButton } from "components/flexComponents/Hotels/components/SeeAllButton";
import { LegalText } from "components/flexComponents/Hotels/components/LegalText";
import { HotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";
import { HotelStarRatingAffinityFilters } from "components/flexComponents/Hotels/components/HotelAffinityFilters/HotelStarRatingAffinityFilters";
import { SchemaOrgScript } from "components/utility/SchemaOrg/SchemaOrgScript";
import { hotelStructuredData } from "components/flexComponents/Hotels/utils/getHotelStructuredData";
import { PlaceholderResults } from "./components/PlaceholderResults/PlaceholderResults";
import { PriceTrendGraphAnchor } from "./components/PriceTrendGraphAnchor/PriceTrendGraphAnchor";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { OverfilteringCard } from "./components/MessagingCard/OverfilteringCard";
import { HotelTitle } from "./components/HotelTitle";
import { useMapPropertySearchById } from "./hooks/useMapPropertySearchById";
import {
  AFFINITY_DESTINATION_LANDING_PAGE_TYPES,
  EG_RECS_STRATEGY,
} from "components/flexComponents/Hotels/utils/constants";

// Types
import { HotelsProps, HotelsQueryComponentProps } from "./typings";
import { Hotel, HotelsFlexModuleResult } from "typings/microserviceModels/hotels-flex-module";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { PriceTrendGraphFlexModuleResult } from "typings/microserviceModels/price-trend-graph-flex-module";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { affinityProductsModulePresentedClickstreamEvent } from "components/flexComponents/Hotels/utils/affinityClickstreamEvents";
import { egRecsClickstreamPresentedEvent } from "components/flexComponents/Hotels/utils/egRecsEvents";
import { useEgClickstream } from "@shared-ui/clickstream-analytics-context";
import { LoyaltySignup } from "../../shared/LoyaltySignup/LoyaltySignup";

/**
 * This component is the main overview of a property and gives basic information
 * to users in the hopes of getting them to click and purchase nights in it.
 *
 * This also handles mobile and desktop views.
 */
const HotelsComponent: React.FC<HotelsProps> = (props: HotelsProps) => {
  const { templateComponent, flexModuleModelStore, hotels: hotelsStore, propertyFilters } = props;

  if (!templateComponent) {
    return null;
  }

  const {
    metadata: { id },
  } = templateComponent;
  const model = flexModuleModelStore.getModel(id) as HotelsFlexModuleResult | null;

  if (!model) {
    return null;
  }

  const hasFilters = (propertyFilters?.getListOfFilters?.().length || 0) > 0;
  const hasFilteredHotels = id === "hotels-1" && hotelsStore.filteredHotels?.length;
  const hotelsList = hasFilteredHotels ? hotelsStore.filteredHotels || [] : model.hotels ?? [];

  if (!hasFilters && hotelsList.length === 0) {
    return null;
  }

  return (
    <HotelsQueryComponent
      hotelsList={hotelsList}
      templateComponent={templateComponent}
      flexModuleModelStore={flexModuleModelStore}
      model={model}
      hotelsStore={hotelsStore}
      context={props.context}
    />
  );
};

const HotelsQueryComponent = observer((props: HotelsQueryComponentProps) => {
  const { templateComponent, flexModuleModelStore, context, model, hotelsStore, hotelsList } = props;
  const {
    metadata: { id },
    config: { fmTitleId },
  } = templateComponent;
  const { hideTitle } = templateComponent.config;

  const fmId = getFmId(templateComponent);
  const locationId = model.destinationId ?? context?.searchContext?.location?.id;
  const track = useEgClickstream();
  const { formatText } = useLocalization();
  useMapPropertySearchById({ ...model, hotels: hotelsList }, hotelsStore, context);

  if (hotelsStore.isLoadingFilterHotels) {
    return <PlaceholderResults />;
  }
  const onChangeFireAffinityProductsModulePresentedEvent = (inView: boolean) => {
    const { searchContext } = context;
    const { pageType, affinity, location } = searchContext || {};
    const isAffinityDestinationLandingPage = pageType
      ? AFFINITY_DESTINATION_LANDING_PAGE_TYPES.includes(pageType)
      : false;
    if (affinity && isAffinityDestinationLandingPage && inView) {
      const event = affinityProductsModulePresentedClickstreamEvent(
        location?.id?.toString(),
        location?.name,
        hotelsList?.length,
        affinity?.code
      );
      track(event);
    }
  };

  const { showSuggestedFilters, showMoreStrategy, showLoyaltySignUp, showPriceTrendGraphAnchor } = model;
  const addTopPaddingLegalText = !showSuggestedFilters && showMoreStrategy !== "button" && hotelsList.length > 1;

  const showOverfilteredMessage = hotelsStore.hasOverfilteredMessage || !hotelsList.length;

  if (!showOverfilteredMessage && hotelsStore.filteredHotels?.length) {
    liveAnnounce(formatText("propertyfilters.results.showing.news"));
  }

  let showPriceTrendMessageCard = false;

  if (showPriceTrendGraphAnchor) {
    const priceTrendGraphModel = flexModuleModelStore.getModel(
      "price-trend-graph-1"
    ) as PriceTrendGraphFlexModuleResult | null;
    showPriceTrendMessageCard = !!priceTrendGraphModel?.priceTrend;
  }

  return (
    <InView as="div" onChange={onChangeFireAffinityProductsModulePresentedEvent} triggerOnce>
      <HotelsContext.Provider value={{ context, templateComponent, model }}>
        <SchemaOrgScript structuredData={hotelStructuredData(hotelsList)} />
        <EGDSSpacing padding={{ blockend: "three" }}>
          <div
            className={`Hotels ${model.showRedPrices ? "hotelPricingApac" : ""}`}
            id={id}
            data-fm={fmId}
            data-fm-title-id={fmTitleId}
          >
            {!hideTitle && <HotelTitle />}
            {model.editorialContent && <EGDSSubheading>{model.editorialContent}</EGDSSubheading>}
            <Viewport>
              <ViewMedium>
                <>
                  {showOverfilteredMessage && <OverfilteringCard />}
                  {!showOverfilteredMessage &&
                    hotelsList.map((hotel, index) => (
                      <Result
                        key={hotel.hotelId}
                        hotel={hotel}
                        index={index}
                        isMobile={false}
                        locationId={locationId}
                        context={context}
                        model={model}
                        cardContent={renderCardContent}
                        hasLoyaltySignUp={showLoyaltySignUp}
                        showPriceTrendGraphAnchor={showPriceTrendMessageCard}
                      />
                    ))}
                </>
              </ViewMedium>
              <ViewSmall>
                <>
                  <SeeAllContentLink
                    model={model}
                    context={context}
                    affinityDescription={context?.searchContext?.affinity?.description}
                  />
                  {showOverfilteredMessage && <OverfilteringCard />}
                  {!showOverfilteredMessage &&
                    hotelsList.map((hotel, index) => (
                      <Result
                        key={hotel.hotelId}
                        hotel={hotel}
                        index={index}
                        isMobile
                        locationId={locationId}
                        context={context}
                        model={model}
                        cardContent={renderCardContent}
                        hasLoyaltySignUp={showLoyaltySignUp}
                        showPriceTrendGraphAnchor={showPriceTrendMessageCard}
                      />
                    ))}
                </>
              </ViewSmall>
            </Viewport>
            <SeeAllButton />
            <HotelStarRatingAffinityFilters />
            <EGDSSpacing padding={{ blockstart: addTopPaddingLegalText ? "three" : "unset" }}>
              <LegalText />
            </EGDSSpacing>
          </div>
        </EGDSSpacing>
      </HotelsContext.Provider>
    </InView>
  );
});

interface ResultProps {
  hotel: Hotel;
  index: number;
  isMobile: boolean;
  locationId: string;
  context: ExtendedContextStore;
  model: HotelsFlexModuleResult;
  cardContent: CardContentFunc;
  hasLoyaltySignUp: boolean;
  showPriceTrendGraphAnchor?: boolean;
}

const Result: React.FC<ResultProps> = ({
  hotel,
  index,
  isMobile,
  locationId,
  context,
  model,
  cardContent,
  hasLoyaltySignUp,
  showPriceTrendGraphAnchor,
}) => {
  const track = useEgClickstream();
  const numberOfCardsToInitiallyShow = isMobile ? 3 : 5;
  const Wrapper = index < numberOfCardsToInitiallyShow ? React.Fragment : LazyLoad;
  const wrapperProps = index < numberOfCardsToInitiallyShow ? null : { context };
  const isEgRecsStrategy = Boolean(EG_RECS_STRATEGY.includes(model.strategy));
  const onChange = (inView: boolean) => {
    const isNewPresentedBehaviour = true;
    if (inView && isEgRecsStrategy) {
      const event = egRecsClickstreamPresentedEvent(model, [hotel], index, isNewPresentedBehaviour);
      track(event);
    }
  };

  const hotelCard = (
    <HotelCard
      hotel={hotel}
      locationId={locationId}
      index={index}
      callToActionHotelName={model.callToActionHotelName}
      context={context}
      CardContent={cardContent(hotel, isMobile, model, context)}
      isMobile={isMobile}
      showGuestRating={model.showGuestRating}
      displayBadges
      withBorder
    />
  );

  const EgEventHotelCard = (
    <InView as="div" onChange={onChange} triggerOnce>
      <HotelCard
        hotel={hotel}
        locationId={locationId}
        index={index}
        callToActionHotelName={model.callToActionHotelName}
        context={context}
        CardContent={cardContent(hotel, isMobile, model, context)}
        isMobile={isMobile}
        showGuestRating={model.showGuestRating}
        displayBadges
        withBorder
      />
    </InView>
  );

  const loyaltyAuth = hasLoyaltySignUp && index === 3 && (
    <EGDSSpacing margin={{ blockstart: "three" }}>
      <div>
        <LoyaltySignup context={context} />
      </div>
    </EGDSSpacing>
  );
  const priceTrendAnchor = showPriceTrendGraphAnchor && index === 6 && (
    <PriceTrendGraphAnchor
      location={context.searchContext.location.localizedName}
      locationType={context.searchContext.location.type}
    />
  );

  const card = isEgRecsStrategy ? EgEventHotelCard : hotelCard;

  return (
    <Wrapper {...wrapperProps}>
      <>
        {loyaltyAuth}
        {priceTrendAnchor}
        {card}
      </>
    </Wrapper>
  );
};

type CardContentFunc = (
  hotel: Hotel,
  isMobile: boolean,
  model: HotelsFlexModuleResult,
  context: ExtendedContextStore
) => () => JSX.Element;

const renderCardContent: CardContentFunc = (hotel, isMobile, model, context) => () => (
  <EGDSCardContentSection>
    <CardContentReviewsPriceProposition
      hotel={hotel}
      locale={context.locale}
      locationName={model.locationName}
      context={context}
      showDistance={model.showDistance}
      showAddress={model.showAddress}
      shouldUseImperialDistanceUnits={model.shouldUseImperialDistanceUnits}
      addressDistanceView={model.addressDistanceView}
    />
  </EGDSCardContentSection>
);

export const Hotels = inject("flexModuleModelStore", "propertyFilters", "context", "hotels")(observer(HotelsComponent));

export default Hotels;
