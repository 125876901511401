import * as React from "react";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSIllustrations } from "@egds/react-core/illustrations";
import { EGDSLink } from "@egds/react-core/link";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { useLocalization } from "@shared-ui/localization-context";
import { FlexClickTracker } from "src/components/utility/analytics/FlexClickTracker/FlexClickTracker";

interface PriceTrendGraphAnchorProps {
  location: string;
  locationType: string;
}

/* istanbul ignore next */
const scrollTo = (): void => {
  const priceTrendElement = document.getElementById("price-trend-graph-1");

  if (priceTrendElement) {
    // Should remove wizard-hotel-pwa-1 or wizard-hotel-pwa-v2-1 after the wizard experiment done
    const wizardElement =
      document.getElementById("wizard-hotel-pwa-1") ?? document.getElementById("wizard-hotel-pwa-v2-1");

    const wizardHeight = wizardElement ? wizardElement.offsetHeight + wizardElement.offsetTop : 0;

    window.scrollTo({
      top:
        priceTrendElement.getBoundingClientRect().top + window.pageYOffset - wizardHeight - priceTrendElement.offsetTop,
    });
    priceTrendElement.focus();
  }
};

export const PriceTrendGraphAnchor: React.FC<PriceTrendGraphAnchorProps> = (props: PriceTrendGraphAnchorProps) => {
  const LinkTracked = FlexClickTracker(EGDSLink);
  const { formatText } = useLocalization();
  const { location, locationType } = props;

  const anchorTitle =
    locationType === "multi_city_vicinity"
      ? formatText("priceTrendGraph.anchor.title.city", location)
      : formatText("priceTrendGraph.anchor.title.country", location);
  const anchorSubtitle =
    locationType === "multi_city_vicinity"
      ? formatText("priceTrendGraph.anchor.subtitle.city", location)
      : formatText("priceTrendGraph.anchor.subtitle.country", location);
  const moreDetailsText = formatText("priceTrendGraph.anchor.moreDetails");
  const illustrationAlt = formatText("priceTrendGraph.anchor.alt");

  return (
    <EGDSSpacing margin={{ blockstart: "three" }}>
      <EGDSCard padded className="PriceTrendGraphAnchor">
        <EGDSLayoutFlex alignItems="center" space="three">
          <EGDSLayoutFlexItem>
            <EGDSIllustrations
              alt={illustrationAlt}
              url="https://a.travel-assets.com/uds/illustrations/price_trend__negative.svg"
            />
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem>
            <div>
              <EGDSHeading tag="h3" size={7}>
                {anchorTitle}
              </EGDSHeading>
              <EGDSText size={300} weight="regular">
                {anchorSubtitle}
              </EGDSText>
              <EGDSSpacing margin={{ blockstart: "two" }}>
                <div>
                  <LinkTracked
                    rfrr="TG.LP.Hotels.PriceTrendGraph.Anchor"
                    onClick={scrollTo}
                    moduleName="PriceTrendGraphAnchor"
                    linkName="RFRR Action Event"
                    inline
                  >
                    <button type="button" onClick={scrollTo}>
                      {moreDetailsText}
                    </button>
                  </LinkTracked>
                </div>
              </EGDSSpacing>
            </div>
          </EGDSLayoutFlexItem>
        </EGDSLayoutFlex>
      </EGDSCard>
    </EGDSSpacing>
  );
};

export default PriceTrendGraphAnchor;
