import * as React from "react";
import { EGDSCard } from "@egds/react-core/cards";
import { EGDSText, EGDSHeading } from "@egds/react-core/text";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSIcon, EGDSIconSize } from "@egds/react-core/icons";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { MessageResult } from "__generated__/typedefs";

export const MessagingCard = ({ title, subtitle }: MessageResult) => {
  /* istanbul ignore next */
  const iconDescription = title?.icon?.description;
  /* istanbul ignore next */
  const iconName = title?.icon?.id;
  /* istanbul ignore next */
  const heading = title?.text!;

  return (
    <EGDSSpacing margin={{ block: "three" }}>
      <div role="alert">
        <EGDSCard padded>
          <EGDSLayoutFlex alignItems="center" space="three">
            {iconName && (
              <EGDSLayoutFlexItem>
                <EGDSIcon
                  id="info-icon"
                  spotlight={{ theme: "info", background: true }}
                  description={iconDescription}
                  name={iconName}
                  size={EGDSIconSize.MEDIUM}
                />
              </EGDSLayoutFlexItem>
            )}
            <EGDSLayoutFlexItem>
              <div>
                <EGDSHeading tag="h3" size={7}>
                  {heading}
                </EGDSHeading>
                {subtitle && <EGDSText size={300}>{subtitle.text}</EGDSText>}
              </div>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </EGDSCard>
      </div>
    </EGDSSpacing>
  );
};

export default MessagingCard;
